.pagination-container {
    display: flex;
    list-style-type: none;
  
    .pagination-item {
      padding: 0.5rem 0.75rem;
      text-align: center;
      margin-left: -1px;
      color: rgb(181, 74, 16);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      line-height: 1.25;
      border: 1px solid #ddd;
  
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
  
      &.selected {
        background-color: rgb(181, 74, 16);
        color: #fff;
        border-color: rgb(181,74,16);
        z-index: 2;
      }
  
      &.disabled {
        pointer-events: none;
  
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }